<template>
  <div class="order">
    <div class="top">
      <div class="con">
        <div class="list">
          <div
            class="listLi"
            v-for="(item, i) in list"
            :key="item.status"
            :class="{ selected: listIndex == i }"
            :style="{ marginRight: lang_data == 1 ? '20px' : '5px' }"
            @click="listCLick(i, item.status)"
          >
            <el-badge :value="item.count > 0 ? item.count : null" class="item">
              {{ lang_data == 1 ? item.name : item.name_en }}
            </el-badge>
          </div>
        </div>
        <div class="add">
          <el-button
            type="primary"
            round
            size="small"
            icon="el-icon-circle-plus-outline"
            @click="
              $router.push({
                name: 'createOrder',
                query: {
                  type: 1,
                },
              })
            "
            >{{ lang_data == 1 ? "新建订单" : "New order" }}</el-button
          >
          <el-button
            round
            size="small"
            type="primary"
            @click="$router.push({ path: '/waitinglist' })"
            icon="el-icon-document-copy"
            >{{
              lang_data == 1 ? "批量上传快递单号" : "Uploading Multiple"
            }}</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="bottom"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <div class="search">
        <el-form inline>
          <el-form-item :label="lang_data == 1 ? '关键字:' : 'Key words'">
            <el-input
              v-model="form.keyword"
              size="small"
              :placeholder="
                lang_data == 1 ? '下单码/订单号/患者' : 'Please enter'
              "
              clearable
              style="width: 160px"
            ></el-input>
          </el-form-item>
          <el-form-item :label="lang_data == 1 ? '项目:' : 'Item'">
            <ProjectTreeSelect
              v-model="form.project_id"
              class="ProjectTreeSelect"
              style="width: 330px"
              all
            ></ProjectTreeSelect>
          </el-form-item>
          <el-form-item :label="lang_data == 1 ? '下单时间:' : 'Order time'">
            <el-date-picker
              size="small"
              style="width: 220px"
              v-model="form.date"
              type="daterange"
              :range-separator="lang_data == 1 ? '至' : '~'"
              :start-placeholder="lang_data == 1 ? '开始日期' : 'Start Date'"
              :end-placeholder="lang_data == 1 ? '结束日期' : 'End Date'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="lang_data == 1 ? '承诺时间:' : 'Time commitment'"
            v-if="show_price && listIndex == 3"
          >
            <el-date-picker
              size="small"
              style="width: 220px"
              v-model="form.date1"
              type="daterange"
              :range-separator="lang_data == 1 ? '至' : '~'"
              :start-placeholder="lang_data == 1 ? '开始日期' : 'Start Date'"
              :end-placeholder="lang_data == 1 ? '结束日期' : 'End Date'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getlist(1)"
              >{{ lang_data == 1 ? "查询" : "Search" }}</el-button
            >
            <a :href="exportlink" download class="linkA"
              ><i class="el-icon-download"></i>
              {{ lang_data == 1 ? "导出" : "Export" }}</a
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="content">
        <el-table
          header-row-class-name="order-table"
          :data="tabledata"
          stripe
          style="width: 100%"
          border
        >
          <el-table-column
            prop="order_no"
            :label="lang_data == 1 ? '订单号' : 'Order No.'"
            width="170"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/orderDetails',
                    query: { id: scope.row.id },
                  })
                "
                >{{ scope.row.order_no }}</el-link
              >
              <el-tooltip
                v-if="scope.row.is_upload_design == 1"
                class="isDesign"
                effect="dark"
                :content="
                  lang_data == 1 ? '有可查看的设计方案' : 'Design scheme'
                "
                placement="right"
              >
                <el-tag size="mini" class="ml10">{{
                  lang_data == 1 ? "设计" : "Design"
                }}</el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="order_code"
            :label="lang_data == 1 ? '下单码' : 'Order Code'"
            width="70"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/orderDetails',
                    query: { id: scope.row.id },
                  })
                "
                >{{ scope.row.order_code }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="factory_name"
            :show-overflow-tooltip="true"
            :label="lang_data == 1 ? '工厂' : 'Factory'"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="clinic_name"
            :show-overflow-tooltip="true"
            :label="lang_data == 1 ? '诊所' : 'Clinic'"
            width="110"
          >
          </el-table-column>
          <el-table-column
            prop="patient_name"
            width="85px"
            :label="lang_data == 1 ? '患者' : 'Patient'"
          >
          </el-table-column>
          <el-table-column :label="lang_data == 1 ? '项目' : 'Product/Service'">
            <template slot-scope="scope">
              {{
                scope.row.project_list
                  .map((item) => item.project_name)
                  .join("、")
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="patient_name"
            width="120px"
            :label="lang_data == 1 ? '当前工序' : 'Current operation'"
          >
            <template slot-scope="{ row }">
              <!-- 加工中才显示当前工序 -->
              <div
                v-if="
                  row.produce_status_show.value == 2 ||
                  row.produce_status_show.value == 3
                "
              >
                {{ row.current_produce_process_name }}
                <span
                  v-if="
                    row.current_produce_process_name &&
                    row.current_produce_process_status_show.label
                  "
                  >(</span
                >
                <span v-if="row.current_produce_process_status_show.label">{{
                  row.current_produce_process_status_show.label
                }}</span>
                <span
                  v-if="
                    row.current_produce_process_name &&
                    row.current_produce_process_status_show.label
                  "
                  >)</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="lang_data == 1 ? '金额' : 'Prices'"
            width="100"
            v-if="show_price1"
          >
            <template slot-scope="scope">
              {{ scope.row.settlement_currency }}{{ scope.row.order_amount }}
            </template>
          </el-table-column>
          <el-table-column
            :label="lang_data == 1 ? '状态' : 'Status'"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                class="status"
                :class="'status_' + scope.row.status_show.value"
                >{{ scope.row.status_show.label }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="express_promise_time"
            width="135px"
            :label="lang_data == 1 ? '承诺时间' : 'Time commitment'"
            v-if="show_price && listIndex == 3"
          >
            <template slot-scope="scope">
              {{
                scope.row.express_promise_time
                  ? $moment(scope.row.express_promise_time * 1000).format(
                      "YYYY-MM-DD hh:mm"
                    )
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="factory_name"
            :label="lang_data == 1 ? '延误天数' : 'Days of delay'"
            v-if="show_price && listIndex == 3"
            width="80"
          >
            <template slot-scope="{ row }">
              {{ row.exceed_promise_day }}
            </template>
          </el-table-column>
          <el-table-column
            prop="add_time"
            width="140px"
            :label="lang_data == 1 ? '下单时间' : 'Order time'"
          >
            <template slot-scope="scope">
              {{
                $moment(scope.row.add_time * 1000).format("YYYY-MM-DD hh:mm")
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="order_code"
            width="100"
            :label="lang_data == 1 ? '售后' : 'After-Sale'"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                v-if="scope.row.reback_info.id"
                @click="
                  $router.push({
                    path: '/afterSalesDetails',
                    query: {
                      rebackid: scope.row.reback_info.order_id,
                      id: scope.row.reback_info.id,
                    },
                  })
                "
              >
                {{
                  scope.row.reback_info.rework_status_show.label
                    ? scope.row.reback_info.rework_status_show.label
                    : scope.row.reback_info.reback_status_show.label
                }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            width="120px"
            :label="lang_data == 1 ? '操作' : 'Action'"
          >
            <template slot-scope="scope">
              <span class="c999" v-if="scope.row.express_send_type == 2">
                {{ lang_data == 1 ? "销售派件" : "Sales package" }}
              </span>
              <el-link
                class="mr10"
                type="primary"
                v-if="[1, 8].includes(scope.row.status_show.value)"
                @click="
                  $router.push({
                    name: 'createOrder',
                    query: {
                      type: 2,
                      id: scope.row.id,
                    },
                  })
                "
                >{{ lang_data == 1 ? "编辑" : "Edit" }}</el-link
              >
              <el-link
                class="mr10"
                type="primary"
                v-if="
                  [4, 5].includes(scope.row.status_show.value) &&
                  scope.row.express_send_type == 1
                "
                @click="showExpress(scope.row.express_order)"
                >{{ lang_data == 1 ? "查看物流" : "Logistics" }}</el-link
              >
              <el-link
                type="danger"
                v-if="[1, 8].includes(scope.row.status_show.value)"
                @click="delOrder(scope.row.id)"
                >{{ lang_data == 1 ? "删除" : "Delete" }}</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="total,jumper,prev, pager, next"
            @current-change="changePage"
            :page-size="page.pageSize"
            :current-page="page.currentPage"
            :total="page.totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <ExpressInfo
      v-if="expressInfoShow"
      :expressInfoShow="expressInfoShow"
      :datainfo="item"
      @cancelEvent="expressInfoShow = false"
    ></ExpressInfo>
  </div>
</template>

<script>
import ProjectTreeSelect from "@/components/select/project-tree-select.vue";
import ExpressInfo from "@/components/expressInfo_modal";
export default {
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      list: [
        { name: "全部订单", count: 0, status: 0, name_en: "Total Orders" },
        {
          name: "待提交",
          count: 0,
          status: -1,
          name_en: "Pending for Submission",
        },
        {
          name: "未接单",
          count: 0,
          status: 1,
          name_en: "Waiting for Accepted",
        },
        { name: "待发货", count: 0, status: 3, name_en: "Ready for Shipping" },
        {
          name: "待签收",
          count: 0,
          status: 4,
          name_en: "Waiting for Receiving",
        },
        { name: "已完成", count: 0, status: 5, name_en: "Finished" },
        { name: "已取消", count: 0, status: 10, name_en: "Cancelled" },
      ],
      listIndex: 0,
      form: {
        keyword: "",
        project_id: [],
        date: [],
        date1: [],
      },
      tabledata: [],
      page: {
        pageSize: 10,
        current_page: 1,
        totalCount: 0,
      },

      loading: false,
      status: "",
      expressInfoShow: false,
      iten: {},
      show_price: false,
      show_price1: false,
    };
  },
  components: {
    ProjectTreeSelect,
    ExpressInfo,
  },
  computed: {
    exportlink() {
      let params = {
        token: localStorage.token,
        export: "export",
        lang_data: localStorage.lang_data == 1 ? 1 : 0,
        doctor_order_status: this.status,
        create_start_time: this.getFormatDate(this.form.date, "start"),
        create_end_time: this.getFormatDate(this.form.date, "end"),
        express_promise_start_time: this.getFormatDate(
          this.form.date1,
          "start"
        ),
        express_promise_end_time: this.getFormatDate(this.form.date1, "end"),
        keyword: this.form.keyword,
        project_id:
          this.form.project_id.length > 0
            ? JSON.stringify(this.form.project_id)
            : "",
      };
      let data = [];
      Object.keys(params).forEach((key) => {
        data.push(`${key}=${params[key]}`);
      });
      return `/apis/orders/orders/getList?` + data.join("&");
    },
  },
  mounted() {
    this.getCount();
    this.getlist(1);
    console.log();
    this.show_price =
      JSON.parse(localStorage.userData) &&
      JSON.parse(localStorage.userData).doctor_info &&
      JSON.parse(localStorage.userData).doctor_info.show_express_promise_time ==
        1;
    this.show_price1 =
      JSON.parse(localStorage.userData) &&
      JSON.parse(localStorage.userData).doctor_info &&
      JSON.parse(localStorage.userData).doctor_info.show_price == 1;
  },
  methods: {
    showExpress(item) {
      this.expressInfoShow = true;
      this.item = item;
    },
    delOrder(id) {
      this.$confirm(
        this.lang_data == 1
          ? "是否确认删除该订单"
          : "Confirm to delete the order",
        this.lang_data == 1 ? "提示" : "Prompt"
      ).then(() => {
        let params = { ids: [id] };
        this.$axios.post("/orders/orders/remove", params).then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.getlist();
            this.$message({
              type: "success",
              message: this.lang_data == 1 ? "删除成功!" : "Delete the success",
            });
          }
        });
      });
    },
    changePage(val) {
      this.getlist(val);
    },
    listCLick(i, val) {
      this.listIndex = i;
      this.status = val;
      this.getlist();
    },
    getlist(current_page) {
      this.loading = true;
      let params = {
        doctor_order_status: this.status,
        create_start_time: this.getFormatDate(this.form.date, "start"),
        create_end_time: this.getFormatDate(this.form.date, "end"),
        express_promise_start_time: this.getFormatDate(
          this.form.date1,
          "start"
        ),
        express_promise_end_time: this.getFormatDate(this.form.date1, "end"),
        keyword: this.form.keyword,
        project_id:
          this.form.project_id.length > 0
            ? JSON.stringify(this.form.project_id)
            : "",
        page_size: this.page.pageSize,
        current_page: current_page ? current_page : this.form.current_page,
      };
      this.$axios.post("/orders/orders/getList", params).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.tabledata = res.data.list;
          this.page.totalCount = res.data.total_count;
          this.page.currentPage = res.data.current_page;
          this.loading = false;
        }
      });
    },
    getCount() {
      this.$axios
        .post("/orders/order_statics/getOrderStatusCount")
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            res.data.list.map((item) => {
              let obj = this.list.filter((ite) => ite.status == item.status)[0];
              if (obj) {
                obj.count = item.count;
              }
            });
          }
        });
    },
    getFormatDate(date, type) {
      if (!date) return "";
      let start = !date[0]
        ? ""
        : this.$moment(date[0]).format("YYYY-MM-DD 00:00:00");
      let end = !date[1]
        ? ""
        : this.$moment(date[1]).format("YYYY-MM-DD 23:59:59");
      if (type == "start") {
        return start;
      }
      if (type == "end") {
        if (!date[1]) return "";
        return end;
      }
      return [start, end];
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  .top {
    width: 100%;
    height: 60px;
    background: #f4f0f2;
    flex: none;

    .con {
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        display: flex;
        .listLi {
          margin-right: 20px;
          padding: 5px 15px;
          cursor: pointer;
        }
        .selected {
          background: #409eff;
          border-radius: 20px;
          color: #fff;
        }
      }
    }
  }
  .bottom {
    width: 1400px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .search {
      min-height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: none;
      .linkA {
        width: 71px;
        height: 27px;
        border: 1px solid #dcdfe6;
        display: inline-block;
        border-radius: 3px;
        line-height: 27px;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 10px;
        font-size: 12px;
      }
      .linkA:hover {
        border: 1px solid #409eff;
        color: #409eff;
      }
    }
    .content {
      height: 100%;
    }
  }
  .page {
    padding: 15px 0;
    float: right;
  }
}
</style>
<style lang="scss">
.order {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>